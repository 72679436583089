<template>
  <b-modal modal-class="modal_basic addReport" id="addReport" hide-footer
           hide-header>
    <img @click="$bvModal.hide('addReport')" class="close_modal" src="/assets/img/cross.svg" alt="">

    <div class="basic_form modal_content">

      <div class="heading">
        <h3>Report</h3>
      </div>

      <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">

        <div class="content">
          <div class="url">Report Name</div>
          <div class="name">
            <div class="validation_input multiple_select">
              <input :class="{'input-field-error': validations.name}" type="text"
                     placeholder="Enter name of report" v-model="getReportsAdd.name" class="">
              <span class="input-error" v-if="validations.name">
                                {{ messages.name }}
                            </span>
            </div>
          </div>
        </div>
      </div>

      <div class=" input_field multiple_select">
        <label for="">Select Brands</label>
        <multiselect :class="{'input-field-error': validations.brands}"
                     v-model="getReportsAdd.brands" placeholder="Search or add a Brand"
                     label="name" track-by="_id" :options="allBrands" :multiple="true"
                     @select="selectBrandForReport"
                     @remove="removeCampaignsFromReport"></multiselect>

        <span class="input-error" v-if="validations.brands">
                                {{ messages.brands }}
                            </span>

      </div>
      <div class=" input_field multiple_select">
        <label for="">Select Campaign</label>

        <multiselect :class="{'input-field-error': validations.campaigns}"
                     v-model="getReportsAdd.campaigns" placeholder="Search or add a Campaign"
                     label="name" track-by="_id" :options="getReportsAdd.campaignsOption"
                     :multiple="true"></multiselect>

        <span class="input-error" v-if="validations.campaigns">
                                {{ messages.campaigns }}
                            </span>

      </div>
      <div class=" input_field email_input"
           :style="{'padding-top: 10px; padding-bottom:10px;': validations.duration}"
           :class="{'input-field-error': validations.duration}">
        <label for="">How often would you like to receive the emails?</label>
        <div class="clearfix"></div>
        <div class="checkbox_input_image">
          <input v-model="getReportsAdd.daily" type="checkbox" id="daily">
          <label for="daily" class="checkbox_left">Daily</label>
        </div>
        <div class="checkbox_input_image">
          <input v-model="getReportsAdd.weekly" type="checkbox" id="weekly">
          <label for="weekly" class="checkbox_left">Weekly</label>
        </div>
        <div class="checkbox_input_image">
          <input v-model="getReportsAdd.monthly" type="checkbox" id="monthly">
          <label for="monthly" class="checkbox_left">Monthly 1st of each month</label>
        </div>
      </div>
      <span class="input-error" v-if="validations.duration">
                            {{ messages.duration }}
                        </span>

      <div class=" input_field nomargin-b multiple_select">
        <label for="">Where should we send an email to?</label>
        <multiselect :class="{'input-field-error': validations.emails}"
                     v-model="getReportsAdd.emails" tag-placeholder="Add this as a new email"
                     placeholder="Search or add an email"
                     label="name" track-by="name" :options="getEmailOptions" :multiple="true"
                     :taggable="true" @tag="addEmail"></multiselect>
        <span class="input-error" v-if="validations.emails">
                                {{ messages.emails }}
                            </span>
        <span class="input-error" v-if="getReportsAdd.emailNotAllowed">
                                Emails more than 25 is not allowed
                            </span>
      </div>

      <div class=" btn_block text-left">

        <button @click.prevent="validateAndStoreReport()" :disabled="getReportsLoaders.store"
                class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--left btn-bold">
          <span v-if="getReportsAdd._id"> Update </span>
          <span v-else> Add </span>
          <clip-loader v-if="getReportsLoaders.store" :size="size" class="ml-1" :color="colorWhite"></clip-loader>
        </button>
        <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                @click="$bvModal.hide('addReport')">
          <span>Close</span>
        </button>

      </div>

    </div>
  </b-modal>

  <!--<div id="addReport" class="modal fade normal_modal form_modal addReport" role="dialog"-->
  <!--tabindex="-1">-->
  <!--<div class="modal-dialog">-->
  <!--<div class="modal-content">-->
  <!--<div class="modal-header">-->

  <!--<button type="button " class="close" data-dismiss="modal" aria-hidden="true">&times;-->
  <!--</button>-->
  <!--<h4 class="modal-title">Report</h4>-->
  <!--</div>-->
  <!--<div class="modal-body">-->

  <!--<div class="clearfix"></div>-->

  <!--<div class="basic_form clear">-->

  <!--<div class="col-md-12 input_field">-->
  <!--<label for="">Report Name</label>-->
  <!--<input :class="{'input-field-error': validations.name}" type="text"-->
  <!--placeholder="Enter name of report" v-model="getReportsAdd.name" class="">-->
  <!--<span class="input-error" v-if="validations.name">-->
  <!--{{messages.name}}-->
  <!--</span>-->
  <!--</div>-->

  <!--<div class="col-md-12 input_field multiple_select">-->
  <!--<label for="">Select Brands</label>-->
  <!--<multiselect :class="{'input-field-error': validations.brands}"-->
  <!--v-model="getReportsAdd.brands" placeholder="Search or add a Brand"-->
  <!--label="name" track-by="_id" :options="getBrandAll" :multiple="true"-->
  <!--@select="selectBrandForReport"-->
  <!--@remove="removeCampaignsFromReport"></multiselect>-->

  <!--<span class="input-error" v-if="validations.brands">-->
  <!--{{messages.brands}}-->
  <!--</span>-->

  <!--</div>-->
  <!--<div class="col-md-12 input_field multiple_select">-->
  <!--<label for="">Select Campaign</label>-->

  <!--<multiselect :class="{'input-field-error': validations.campaigns}"-->
  <!--v-model="getReportsAdd.campaigns" placeholder="Search or add a Campaign"-->
  <!--label="name" track-by="_id" :options="getReportsAdd.campaignsOption"-->
  <!--:multiple="true"></multiselect>-->

  <!--<span class="input-error" v-if="validations.campaigns">-->
  <!--{{messages.campaigns}}-->
  <!--</span>-->

  <!--</div>-->
  <!--<div class="col-md-12 input_field email_input"-->
  <!--:style="{'padding-top: 10px; padding-bottom:10px;': validations.duration}"-->
  <!--:class="{'input-field-error': validations.duration}">-->
  <!--<label for="">How often would you like to receive the emails?</label>-->
  <!--<div class="clearfix"></div>-->
  <!--<div class="checkbox">-->
  <!--<input v-model="getReportsAdd.daily" type="checkbox" id="daily">-->
  <!--<label for="daily">Daily</label>-->
  <!--</div>-->
  <!--<div class="checkbox">-->
  <!--<input v-model="getReportsAdd.weekly" type="checkbox" id="weekly">-->
  <!--<label for="weekly">Weekly</label>-->
  <!--</div>-->
  <!--<div class="checkbox">-->
  <!--<input v-model="getReportsAdd.monthly" type="checkbox" id="monthly">-->
  <!--<label for="monthly">Monthly 1st of each month</label>-->
  <!--</div>-->
  <!--</div>-->
  <!--<span class="input-error" v-if="validations.duration">-->
  <!--{{messages.duration}}-->
  <!--</span>-->

  <!--<div class="col-md-12 input_field nomargin-b multiple_select">-->
  <!--<label for="">Where should we send an email to?</label>-->
  <!--<multiselect :class="{'input-field-error': validations.emails}"-->
  <!--v-model="getReportsAdd.emails" tag-placeholder="Add this as a new email"-->
  <!--placeholder="Search or add an email"-->
  <!--label="name" track-by="name" :options="getEmailOptions" :multiple="true"-->
  <!--:taggable="true" @tag="addEmail"></multiselect>-->
  <!--<span class="input-error" v-if="validations.emails">-->
  <!--{{messages.emails}}-->
  <!--</span>-->
  <!--</div>-->

  <!--</div>-->

  <!--</div>-->

  <!--<div class="modal-footer clear">-->

  <!--<a href="javascript:;" @click.prevent="validateAndStoreReport()"-->
  <!--class="btn&#45;&#45;cta btn&#45;&#45;small btn&#45;&#45;loader white&#45;&#45;loader loader&#45;&#45;left">-->
  <!--<clip-loader v-if="getReportsLoaders.store" :size="size" :color="colorWhite"></clip-loader>-->
  <!--<span v-if="getReportsAdd._id"> Update </span>-->
  <!--<span v-else> Add </span>-->
  <!--</a>-->
  <!--<button type="button" class="btn&#45;&#45;cta btn&#45;&#45;small btn-&#45;&#45;transparent " data-dismiss="modal">Close-->
  <!--</button>-->

  <!--</div>-->

  <!--</div>-->

  <!--</div>-->
  <!--</div>-->

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'
import Multiselect from 'vue-multiselect'
import { reportTypes } from '@/state/modules/mutation-types'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'

export default ({
  components: {
    Dropdown,
    Multiselect
  },
  data () {
    return {
      size: '14px',
      color: DARK_PURPLE_LOADER_COLOR,
      colorWhite: '#ffffff',
      messages: {
        name: 'Name cannot be empty',
        brands: 'Brands cannot be empty',
        campaigns: 'Campaigns cannot be empty',
        duration: 'Please select how often would you like to receive the emails',
        emails: 'Please add email address on which you would like to receive the reports.'
      },
      validations: {
        name: false,
        brands: false,
        campaigns: false,
        duration: false,
        emails: false
      },
      allBrands: [],
      allCampaign: []
    }
  },
  async created () {
    this.allBrands = await this.fetchBrandList({ page: 1, isFetchAll: true })
    this.allCampaign = await this.fetchCampaignsList({ page: 1, isFetchAll: true })
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getReportsAdd',
      'getReportsLoaders'
    ]),
    getEmailOptions () {
      if (!this.getReportsAdd.emailsOption.find(item => item.name === this.getProfile.email)) {
        if (this.getProfile.email) {
          this.getReportsAdd.emailsOption.push({
            'name': this.getProfile.email
          })
        }
      }
      return this.getReportsAdd.emailsOption
    }
  },
  methods: {
    ...mapActions(['fetchBrandList', 'fetchCampaignsList']),
    selectBrandForReport (value) {
      let campaignsList = this.allCampaign.filter(item => item.brand_id === value._id)
      if (campaignsList) {
        this.$store.commit(reportTypes.SET_REPORTS_ADD_CAMPAIGNS_SELECTION, this.getReportsAdd.campaigns.concat(campaignsList))
        this.$store.commit(reportTypes.SET_REPORTS_ADD_CAMPAIGNS_OPTION, this.getReportsAdd.campaignsOption.concat(campaignsList))
      }
    },
    removeCampaignsFromReport (value) {
      let campaignsIds = this.allCampaign.map(item => {
        if (item.brand_id === value._id) {
          return item._id
        }
      })
      let campaignsSelection = this.getReportsAdd.campaigns.filter(item => campaignsIds.indexOf(item._id) < 0)
      let campaignsOptions = this.getReportsAdd.campaignsOption.filter(item => campaignsIds.indexOf(item._id) < 0)
      this.$store.commit(reportTypes.SET_REPORTS_ADD_CAMPAIGNS_SELECTION, campaignsSelection)
      this.$store.commit(reportTypes.SET_REPORTS_ADD_CAMPAIGNS_OPTION, campaignsOptions)
    },
    addEmail (emailAddress) {
      if (this.getReportsAdd.emails.length < 25) {
        if (this.validateEmail(emailAddress)) {
          const email = {
            name: emailAddress
          }
          this.$store.commit(reportTypes.SET_REPORTS_ADD_EMAIL_SELECTION, [...new Set(this.getReportsAdd.emails.concat(email))])
          this.$store.commit(reportTypes.SET_REPORTS_ADD_EMAIL_OPTIONS, [...new Set(this.getReportsAdd.emailsOption.concat(email))])
        } else {
          this.alertMessage('Please add valid email.', 'error')
        }
      } else {
        this.getReportsAdd.emailNotAllowed = true
      }
    },
    async validateAndStoreReport () {
      this.validations.name = this.requiredCheck(this.getReportsAdd.name)
      this.validations.brands = (this.getReportsAdd.brands.length === 0)
      this.validations.campaigns = (this.getReportsAdd.campaigns.length === 0)
      this.validations.emails = (this.getReportsAdd.emails.length === 0)
      this.validations.duration = (!this.getReportsAdd.weekly && !this.getReportsAdd.daily && !this.getReportsAdd.monthly)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        await this.$bvModal.hide('addReport')
        await this.$store.dispatch('storeReport')
      }
    }
  },
  watch: {
    'getReportsAdd.name' (value) {
      if (value && value.trim() && value.length > 0) this.validations.name = false
    },
    'getReportsAdd.brands' (value) {
      if (value && value.length > 0) this.validations.brands = false
    },
    'getReportsAdd.campaigns' (value) {
      if (value && value.length > 0) this.validations.campaigns = false
    },
    'getReportsAdd.emails' (value) {
      if (value && value.length > 0) this.validations.emails = false
    }
  }
})
</script>
