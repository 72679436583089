<template>

  <div class="report_component">

    <b-modal id="email-report-logs" title="Email Report Logs" hide-footer>
      <div class="report-log" v-if="self.getEmailReports && self.getEmailReports.length > 0"
           v-for="report in self.getEmailReports">
        A <span>{{ report.type }}</span> report is generated and sent on <span>{{ report.email }}</span> at
        {{ report.created_at.toString().slice(0, -3) }}
        <hr>
      </div>

      <div class="no_logs" v-if="self.getEmailReports.length == 0">
        No logs found for your reports.
      </div>

    </b-modal>

    <transition name="slide">
      <!--<sidebar></sidebar>-->

      <div class="container">

        <add-report></add-report>

        <div class="report_component_inner">

          <div class="component_head d-flex align-items-center">
            <div class="head_left">
              <!-- <button
                v-if="getProfile.policy.can_add_reports"
                @click.prevent="showReportModal()"
                class="btn---cta btn-blue with-shadow btn-round   btn-bold"
              >
                <span>Add Report</span>
                <span class="icon_right">
                  <i class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                </span>
              </button> -->
              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'Add Report'"
                :icon="'fal fa-plus'"
                :iconPosition="'right'"
                :iconBackground="'rgba(31, 33, 110, 0.35)'"
                :iconBorderRadius="'50%'"
                v-if="self.getProfile.policy.can_add_reports"
                v-on:click="showReportModal()"
              />

            </div>
            <div class="head_right ml-auto d-flex align-items-center justify-content-end">

            </div>

          </div>

          <div class="white_box ">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center">
                <div class="left">
                  <h2>Reports</h2>

                </div>
                <div class="right ml-auto d-flex align-items-center justify-content-end">

                  <div class="search_input_block no_animation  ">
                    <input
                      placeholder="Search Report"
                      type="text"
                      v-model="self.getReports.search"
                      @keyup.enter="self.fetchReports(1)"
                    >
                    <i
                      class="fal fa-search"
                      @click.prevent="self.fetchReports(1)"
                    ></i>
                  </div>

                  <!--                  <button @click.prevent="$bvModal.show('email-report-logs')"-->
                  <!--                          class="btn-&#45;&#45;cta light-blue btn-round ml-2">-->
                  <!--                    <span>Logs</span>-->
                  <!--                  </button>-->

                  <div class="ml-2 toggle_btn_link with_border ">
                    <div class="toggle_btn_inner">
                      <a
                        @click.prevent="showArchiveReports(false)"
                        class="btn_link"
                        :class="{'active_tab':!self.getReports.archiveStatus}"
                      >
                        Active Reports
                      </a>
                      <a
                        @click.prevent="showArchiveReports(true)"
                        class="btn_link"
                        :class="{'active_tab':self.getReports.archiveStatus }"
                      >
                        Archived Reports
                      </a>

                    </div>
                  </div>
                  <!--<button v-if="getReports.archiveStatus" @click.prevent="showArchiveReports(false)"-->
                  <!--class="btn-&#45;&#45;cta btn-border btn-round  btn-bold">-->
                  <!--<span>Show Active Reports</span>-->
                  <!--</button>-->
                  <!--<button v-else @click.prevent="showArchiveReports(true)"-->
                  <!--class="btn-&#45;&#45;cta btn-border btn-round  btn-bold">-->
                  <!--<span>Show Archived Reports</span>-->
                  <!--</button>-->

                </div>

              </div>
              <div class="box_content">

                <div class="table_container">
                  <table class="table list-content">
                    <thead class=" filter_head">
                    <tr>
                      <th
                        style="text-align: left;width: 250px;"
                        class="name"
                      >Name
                      </th>
                      <th
                        class=""
                        style="width: 100px;text-align: center;"
                      >Email
                      </th>
                      <th
                        class=""
                        style="width: 100px;text-align: center;"
                      >Daily
                      </th>
                      <th
                        class=""
                        style="width: 100px;text-align: center;"
                      >Weekly
                      </th>
                      <th
                        class=""
                        style="width: 100px;text-align: center;"
                      >Monthly
                      </th>
                      <th style="width: 150px;text-align: right;">Actions</th>
                    </tr>
                    </thead>
                    <tbody v-if="self.getReportsLoaders.retrieve">
                    <tr>
                      <td colspan="6">
                        <p style="padding: 10px;">
                          <clip-loader
                            :size="size"
                            :color="color"
                          ></clip-loader>
                        </p>

                      </td>
                    </tr>
                    </tbody>
                    <tbody v-else-if="getReports.items.length === 0">
                    <tr>
                      <td colspan="9" class="">
                        <p class="no-rows"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{
                            getFilteredMessage('reports', self.getReports.search, 'All Time', self.getReports.archiveStatus)
                          }}</p>
                      </td>
                    </tr>
                    </tbody>
                    <tbody
                      v-else
                      class="list-content-body"
                    >

                    <tr v-for="(report,index) in self.getReports.items">
                      <td>
                        <div
                          class="report_detail"
                          :class="{'overlay_item archived':self.getReports.archiveStatus}"
                        >
                          <h3>{{ report.name }}</h3>
                          <p>You have {{ report.brands.length }} brands and {{ report.campaigns.length }}
                            campaigns...</p>
                        </div>
                      </td>

                      <td class="text-center">
                        <div :class="{'overlay_item archived':self.getReports.archiveStatus}">
                          <template v-if="report.emails.length === 1">
                              <span>
                                {{ report.emails[0] }}
                              </span>
                          </template>
                          <template v-else-if="report.emails.length > 1">
                            {{ report.emails[0] }}

                            <div class="view_more custom_tooltip">

                                <span class="view_text">
                                  View {{ report.emails.length - 1 }} more address
                                </span>
                              <div class="tool_tip_box">
                                <div class="inner_box">
                                  <li
                                    style="list-style: none; text-align: center;"
                                    v-for="email in report.emails.slice(1)"
                                  >{{ email }}
                                  </li>
                                </div>
                              </div>

                            </div>
                          </template>
                          <!--<span v-for="email in report.emails">{{email}}</span>-->
                        </div>
                      </td>
                      <td class="text-center">
                        <div :class="{'overlay_item archived':self.getReports.archiveStatus}">
                          <template v-if="report.daily">
                              <span class="round_icon allowed">
                                <i class="far fa-check"></i>
                              </span>
                          </template>
                          <template v-else>
                              <span class="round_icon not_allowed">
                                <i class="fal fa-times"></i>
                              </span>
                          </template>
                        </div>
                      </td>
                      <td class="text-center">
                        <div :class="{'overlay_item archived':self.getReports.archiveStatus}">
                          <template v-if="report.weekly">
                              <span class="round_icon allowed">
                                <i class="far fa-check"></i>
                              </span>
                          </template>
                          <template v-else>
                              <span class="round_icon not_allowed">
                                <i class="fal fa-times"></i>
                              </span>
                          </template>
                        </div>
                      </td>
                      <td class="text-center">
                        <div :class="{'overlay_item archived':self.getReports.archiveStatus}">
                          <template v-if="report.monthly">
                              <span class="round_icon allowed">
                                <i class="far fa-check"></i>
                              </span>
                          </template>
                          <template v-else>
                              <span class="round_icon not_allowed">
                                <i class="fal fa-times"></i>
                              </span>
                          </template>
                        </div>
                      </td>
                      <td class=" text-right">
                        <div
                          class="actions"
                          v-if="report.archive"
                        >
                          <i
                            v-tooltip="'Unarchive'"
                            class="far fa-recycle "
                            @click.prevent="self.changeArchive({reportId: report._id, index: index, status: false})"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <template v-else>

                          <b-dropdown
                            ref="dropdownActionMenu"
                            right
                            offset="13"
                            class="d-inline-block table_action_dropdown  dropdown-menu-right default_style_dropdown"
                            :no-caret="true"
                          >
                            <!--d-flex align-items-center-->
                            <div
                              class=" dropdown_header no_arrow text-right"
                              slot="button-content"
                            >
                                <span class="text">
                                  <i class="fal fa-ellipsis-h"></i>
                                </span>
                              <!--<span class="arrow_icon ml-auto">-->
                              <!--<i class=" fal fa-angle-down ml-3"></i>-->
                              <!--</span>-->
                            </div>

                            <ul class="clear inner ">
                              <li
                                class="list_item"
                                @click.prevent="editReport(report)"
                              >
                                <a href="">
                                  <i
                                    class="far  fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                  Edit
                                </a>
                              </li>
                              <li class="list_item" @click.prevent="getEmailLog(report._id)">
                                <a href="">
                                  <i
                                    class="far  fa-list"
                                    aria-hidden="true"
                                  ></i>
                                  Activity
                                </a>
                              </li>
                              <li class="list_item" @click.prevent="dispatchReport(report._id)">
                                <a href="">
                                  <i
                                    class="far fa-chart-bar"
                                    aria-hidden="true"
                                  ></i>
                                  Dispatch Report
                                </a>
                              </li>
                              <li class="list_item">
                                <a
                                  href=""
                                  @click.prevent="self.changeArchive({reportId: report._id, index: index, status: true})"
                                >
                                  <i
                                    class="far  fa-recycle"
                                    aria-hidden="true"
                                  ></i> Archive
                                </a>
                              </li>
                              <li class="list_item">
                                <a
                                  href=""
                                  @click.prevent="self.deleteReport({reportId: report._id,index: index})"
                                >
                                  <i
                                    class="far  fa-trash"
                                    aria-hidden="true"
                                  ></i> Remove
                                </a>
                              </li>

                            </ul>

                          </b-dropdown>

                        </template>

                      </td>
                    </tr>

                    </tbody>
                  </table>
                </div>

                <template v-if="!self.getReportsLoaders.retrieve">
                  <template v-if="self.getReports.total_items > self.getReports.limit">
                    <pagination
                      :clickedMethod="paginateReports"
                      :resultCount="self.getReports.total_items"
                      :itemsPerPage="self.getReports.limit"
                      :currentPage="self.getReports.page"
                      :totalPagesNumber="self.getReports.total_pages"
                    ></pagination>
                  </template>

                </template>

              </div>

            </div>

          </div>

        </div>

      </div>
    </transition>
  </div>

</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.btn---cta.light-blue span {
  color: #1f216e;
}

.report-log span {
  color: #3988fe;
}

.no_logs {
  text-align: center;
  padding: 25px 0;
  font-size: 14px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import Headerbar from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import AddReport from './dialogs/AddReportModal.vue'
import Dropdown from '@/ui/Dropdown.vue'
import reports from '@/state/modules/reports'
import { reportTypes } from '@/state/modules/mutation-types'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'

export default ({
  components: {
    Headerbar,
    Sidebar,
    Dropdown,
    AddReport
  },
  data () {
    return {
      emailReports: [],
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      self: '',
      allBrands: [],
      allCampaign: []
    }
  },
  async created () {
    this.self = this
    await this.self.$store.registerModule('reports', reports)
    await this.self.fetchEmailReports()
    await this.self.fetchReports()
    this.allBrands = await this.self.fetchBrandList({ page: 1, isFetchAll: true })
    this.allCampaign = await this.self.fetchCampaignsList({ page: 1, isFetchAll: true })
  },
  beforeDestroy () {
    this.self.$store.unregisterModule('reports')
  },
  computed: {
    ...mapGetters(['getReportsAdd', 'getReports', 'getReportsLoaders', 'getEmailReports'])
  },
  methods: {
    ...mapActions([
      'fetchReports',
      'deleteReport',
      'changeArchive',
      'fetchEmailReports',
      'dispatchManualReports',
      'fetchBrandList',
      'fetchCampaignsList'
    ]),
    showReportModal () {
      this.self.$store.commit(reportTypes.SET_REPORTS_ADD_DEFAULT_VALUE)
      // $('#addReport').modal('show')
      this.$bvModal.show('addReport')
    },
    editReport (report) {
      let reportAdd = this.self.getReportsAdd
      let campaigns = []
      let campaignsOptions = []
      let emailsList = []
      this.self.allCampaign.forEach(campaign => {
        campaignsOptions.push(campaign)
        if (report.campaigns.includes(campaign._id)) {
          campaigns.push(campaign)
        }
      })
      report.emails.forEach(item => {
        emailsList.push({ name: item })
      })
      this.$set(reportAdd, 'brands', this.self.allBrands.filter(item => report.brands.includes(item._id)))
      this.$set(reportAdd, 'campaigns', campaigns)
      this.$set(reportAdd, 'campaignsOptions', campaignsOptions)
      this.$set(reportAdd, 'name', report.name)
      this.$set(reportAdd, 'monthly', report.monthly)
      this.$set(reportAdd, 'weekly', report.weekly)
      this.$set(reportAdd, 'daily', report.daily)
      this.$set(reportAdd, '_id', report._id)
      this.$set(reportAdd, 'emails', emailsList)
      this.$set(reportAdd, 'emailsOption', emailsList)
      // $('#addReport').modal('show')
      this.$bvModal.show('addReport')
    },
    paginateReports (page) {
      this.fetchReports(page)
    },
    showArchiveReports (status) {
      this.self.$store.commit(reportTypes.SET_REPORTS_ARCHIVE_STATUS, status)
      this.self.$store.commit(reportTypes.SET_REPORTS_SEARCH_VALUE, '')
      this.self.fetchReports()
    },
    getEmailLog (reportId) {
      this.emailReports = this.self.fetchEmailReports(reportId)
      this.$bvModal.show('email-report-logs')
    },
    async dispatchReport (reportId) {
      let res = await this.self.dispatchManualReports(reportId)
      if (res) {
      }
    }
  },
  watch: {
    async 'self.getReports.search' (value) {
      if (value.length === 0) {
        await this.self.fetchReports(1)
      } else if (value && value.length >= 3) {
        await this.self.fetchReports(1)
      }
    }
  }
})
</script>
